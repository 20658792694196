// Hero Section - First Section -----------------
.home_page_hero_section {
  background-color: var(--primary-color);
  padding: 43px 0 65px;
  .home_hero_title {
    font-size: clamp(48px, 6dvw, 72px) !important;
    max-width: 888px;
    line-height: 1.17em;
  }
  .home_hero_info {
    .null {
      color: inherit;
      text-underline-offset: 4px;
    }
  }
}

// Tab section - Second Section ----------------
.home_tab_section {
  .container_Mid_Width {
    max-width: 1200px !important;
  }
  .tab_title {
    background-color: var(--primary-color);
    .single_tab_item {
      color: var(--white);
      padding: 0;
      font-size: 18px;
      text-transform: unset !important;
      font-weight: 400;
      &:not(:last-child) {
        margin-right: 60px;
      }
    }
  }
  .tab_content {
    padding: 30px 0 50px;
    .inner_tab_content {
      .tab_Content_Item {
        border: 2px solid var(--primary-color);
        transition: all 0.4s ease-in-out;
        border-radius: 5px;
        &:hover {
          background-color: var(--accent-purple-a);
          border-color: var(--accent-purple-a);
        }
        &:not(:last-child) {
          margin-bottom: 15px;
        }
        .tab_Row {
          display: flex;
          justify-content: space-between;
          min-height: 160px;
          .icon_Wrapper {
            flex: 0 0 120px;
            padding: 10px !important;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .content_Wrapper {
            flex: 0 0 calc(100% - 120px);
            padding: 20px 70px 20px 40px !important;
            display: flex;
            align-items: center;
            position: relative;
            @media (min-width: 1025px) and (max-width: 1200px) {
              padding: 20px 30px 20px 30px !important;
            }
            &::before {
              content: "";
              position: absolute;
              left: 0;
              height: calc(100% + 4px);
              width: 2px;
              background-color: var(--primary-color);
            }
            .content_Wrapper_Row {
              justify-content: space-between;
              align-items: center;
              h5 {
                line-height: 2em;
                color: var(--primary-color);
                font-weight: 900;
              }
              p {
                color: var(--primary-color);
              }
              a {
                padding: 10px 26px;
                color: var(--white);
                background-color: var(--accent-purple-b);
                font-size: 16px;
                text-transform: unset !important;
                line-height: 1.313em;
                text-decoration: none;
                border-radius: 5px;
                display: inline-block;
                svg {
                  path {
                    fill: var(--white);
                  }
                }
              }
            }
          }
        }
      }
      .coming_Soon_Alert {
        border: 2px solid var(--primary-color);
        border-radius: 5px;
        padding: 30px 40px;
        text-align: center;
        h5{
          font-weight: 900;
          margin: 0 0 10px;
        }
      }
    }
  }
}

// RESPONSIVE CSS STARTS FROM HERE. PLEASE INSERT ANY CSS PROPERTIES THAT DOES NOT FALL FOR A SPECIFIC DEVICES ABOVE THIS COMMENT.
@media (max-width: 767px) {
  .home_page_hero_section {
    padding: 43px 0 40px;
  }
  .home_tab_section {
    .container_Mid_Width {
      padding: 0 20px !important;
    }
    .tab_content {
      .inner_tab_content {
        .tab_Content_Item {
          .tab_Row {
            .icon_Wrapper {
              display: none !important;
            }
            .content_Wrapper {
              padding: 20px 16px 20px 26px !important;
              flex: unset;
              &::before {
                display: none;
              }
              .content_Wrapper_Row {
                h5 {
                  font-size: 21px !important;
                  line-height: 1.4em;
                }
                p {
                  line-height: 1.313em !important;
                  margin: 8px 0 14px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .home_tab_section {
    .content_Wrapper_Row {
      flex-flow: column;
      align-items: flex-start !important;
      .content_Right {
        margin: 14px 0 0;
      }
    }
  }
}

@media (min-width: 1025px) and (max-width: 1200px) {
  .home_tab_section {
    .content_Wrapper {
      .content_Wrapper_Row {
        flex-flow: row;
        .content_Left {
          width: calc(100% - 200px);
        }
        .content_Right {
          margin: 14px 0 0;
        }
      }
    }
  }
}
