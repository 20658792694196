.emailTableSection {
  .subjectLineContent {
    padding: 0px 100px 0px 50px;
    width: 100%;
    margin: 8px 0;
    .contentSpace {
      padding-right: 16px;
    }
  }

  .iconsWrap {
    display: flex;
    padding-right: 20px;

    img {
      margin-right: 11px;
      width: auto;
      height: auto;
      color: var(--grey-a);
    }
  }

  table {
    .readTableRow {
      background-color: #f7f7f7;
      b,
      p,
      span {
        font-weight: 400 !important;
      }
    }
    tr:last-child td {
      border-bottom: none;
    }

    th,
    .nameTitle {
      font-weight: 700;
    }

    td {
      display: flex;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;
      padding-top: 0px;
      padding-bottom: 0px;
      .emailNameTitle {
        min-width: 140px;
        line-height: 1 !important;
        margin: 0;
        .nameTitle {
          max-width: 140px;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          line-height: 1.4;
        }
      }

      &:hover {
        border-radius: 0;
        background: #f7f7f7;
      }
    }

    th {
      color: var(--black-a);
    }

    @media (max-width: 767px) {
      tr td {
        display: block;
        white-space: normal;
        .subjectLineContent {
          padding: 0px 0px;
          margin-top: 0px;

          span {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box !important;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
          }
        }

        .mobflex {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0px;
          align-items: center;
        }
        .emailNameTitle {
          .nameTitle {
            max-width: calc(100% - 80px) !important;
            white-space: pre-line;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box !important;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
          }
        }
      }
    }

    @media (max-width: 767px) {
      min-width: 100%;
    }
  }

  .showMobile {
    display: none;
  }

  .showDesk {
    display: block;
  }

  @media (max-width: 767px) {
    .contentSpace,
    .subjectLineContent span,
    .showMobile {
      padding: 0 !important;
      line-height: 1.3 !important;
      font-size: 12px;
      margin: 2px 0;
    }
    .showDesk {
      display: none;
    }

    .showMobile {
      display: block;
    }
  }
}
