.footerMain {
  background: var(--primary-color);
  .footerTop {
    padding: 80px 0 90px;
    .inner {
      max-width: 1300px;
      margin: 0 auto;
      .footerContentBox {
        padding: 0 90px 0 0;
      }
      p {
        line-height: 1.32em !important;
      }
      h6 {
        line-height: 1.67em;
        &:not(:last-child) {
          margin-bottom: 30px;
        }
      }
      .footerLink{
        text-decoration: none;
        color: #fff;
        svg{
          margin-left: 10px;
        }
      }
    }
  }
  .footerBottom {
    padding: 0 0 50px;
    .inner {
      max-width: 1300px;
      margin: 0 auto;
    }
  }
}

@media only screen and (max-width: 899px) {
  .footerMain {
    padding: 0 12px;
    .footerTop {
      padding: 80px 0 60px;
      .secondColm{
        margin-top: 60px;
      }
      .inner {
        h3 {
          font-size: 24px !important;
        }
        h6{
          font-size: 18px !important;
        }
        .footerContentBox{
          padding: 0 !important;
        }
      }
    }
    .footerBottom {
      .inner {
        max-width: 100% !important;
        gap: 20px;
        h6{
          font-size: 14px !important;
        }
        svg{
          width: 100px;
          height: auto;
        }
      }
    }
  }
}

@media (min-width:900px) and (max-width:1024px){
  .footerMain {
    .footerTop {
      .inner {
        h6{
          font-size: 16px !important;
        }
        .footerContentBox{
          padding: 0 40px 0 0 !important;
        }
      }
    }
    .footerBottom {
      .inner {
        h6{
          font-size: 16px !important;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1280px) {
  .footerBottom {
    .inner {
      max-width: 100% !important;
      gap: 20px;
    }
  }
}
