.emailpreviewWrap {
  padding-bottom: 65px;
}

// emailToolWrap

.emailToolWrap {
  padding: 0 0 40px;
  @media (min-width: 900px) {
    margin-top: -240px;
  }

  /* .wrapper {
    padding-left: 16px !important;
    padding-right: 16px !important;
  } */

  .rightArea {
    button {
      border-radius: 5px;
      margin: 0 5px;
      box-shadow: none;
      font-size: 14px;
      line-height: normal;
      font-weight: 700;
      padding: 14px 35px;
      transition: all 0.4s ease-in-out;
      text-transform: unset;

      &:hover {
        background-color: var(--secondary-color) !important;
        color: var(--white) !important;

        svg path {
          fill: var(--white);
        }
      }

      &.btnFilled {
        padding: 14px 50px !important;

        &:hover {
          background-color: var(--primary-color) !important;
        }
      }

      svg {
        margin: 0 10px 0 0;

        path {
          transition: all 0.4s ease-in-out;
        }
      }
    }

    .innerRight {
      width: 100%;
      display: flex;
      height: 100%;
      flex-flow: column;
      justify-content: stretch;

      label {
        color: var(--grey-c);
        font-size: 12px;
        font-weight: normal;
        line-height: 2;
        display: block;
      }

      textarea {
        resize: none;
        border-radius: 5px;
        border: 1px solid #ccc;
        width: 100%;
        background: #fff;
        color: var(--black-a);
        padding: 18px;
        font-size: 1rem;
        height: calc(100% - 46px) !important;
        &::placeholder {
          color: var(--grey-a);
          line-height: 1.5;
        }
      }
    }
  }

  // workingArea
  .workingArea {
    background-color: var(--white);
    border: 1px solid var(--grey-d);
    margin: 0 auto;
    border-radius: 10px;
    padding: 34px 46px 30px;
    gap: 0 30px;
    width: 100%;

    .messageBoxes {
      label {
        color: var(--grey-c);
        font-size: 12px;
        font-weight: normal;
        line-height: 2;
        display: block;

        &:not(:first-child) {
          margin: 20px 0 0 0;
        }
      }

      textarea {
        border-radius: 5px;
        border: 1px solid var(--grey-a);
        background: var(--white);
        resize: none;
        padding: 18px 25px 25px 18px;
        outline: none !important;
        font-size: 16px;
        line-height: 1.5;

        &::placeholder {
          color: var(--grey-a);
          line-height: 1.5;
        }
      }
    }

    & > div {
      padding: 0;
    }

    .alertBox {
      background-color: var(--light-peace);
      padding: 12px 15px;
      border-radius: 8px;
      margin-bottom: 14px;

      svg {
        min-width: 20px;
      }
    }

    .leftArea {
      input {
        width: 100%;
        padding: 13px 16px !important;
        color: var(--black-a);
        .Mui-focused {
          border: 3px solid red;
        }
      }

      .inputFields {
        margin-bottom: 22px;

        .numberFieldText {
          text-align: right;
          display: block;
          margin-top: 5px;
          margin-bottom: -20px;
          color: var(--grey-c);
          font-size: 12px;
        }

        > div {
          width: 100%;
        }
      }
    }

    .rightArea {
      position: relative;
      flex: 1;
    }
  }
}

// emailTableSection - Gmail
.emailTableSection {
  margin-bottom: 40px;

  .wrapper {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .tableSecContent {
    background-color: var(--white);
    border: 1px solid var(--grey-d);
    margin: 0 auto;
    border-radius: 10px;
    padding: 3px 0px 0px;
    gap: 0 30px;
    width: 100%;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
  }
}

// emailLetterSec
.emailLetterSec {
  .wrapper {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .emailLetterContent {
    background-color: var(--white);
    border: 1px solid var(--grey-d);
    margin: 0 auto;
    border-radius: 10px;
    padding: 50px 60px 70px;
    width: 100%;
    border-radius: 10px;

    .secHeadingTitle {
      .secTitle {
        font-weight: normal;
      }
    }

    .iconWrap {
      img {
        margin-right: 40px;

        &:last-child {
          margin-right: 0px;
        }
      }
    }

    .emailContent {
      display: flex;

      .emailThumb {
        width: 60px;

        .thumbBox {
          width: 35px;
          height: 35px;
          margin-right: 30px;
          background-color: #d9d9d9;
          display: block;
          border-radius: 100px;
        }
      }

      .emailwriteContent {
        width: calc(100% - 60px);
        .shareEmailContent {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-bottom: 50px;
          .leftSection {
            .emailLayer {
              span {
                font-size: 12px;
              }
            }
          }
          .leftSection,
          .rightSection {
            display: flex;

            .titleName {
              margin-right: 3px;
              word-break: break-all;
            }

            .iconWrap {
              margin-left: 30px;

              img {
                margin-right: 30px;

                &:last-child {
                  margin-right: 0px;
                }
              }
            }
            .showMobile {
              display: none;
            }
          }
        }
        .letterText {
          p {
            margin-top: 0px;
            white-space: pre-line;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .emailLetterContent {
      padding: 20px 15px 40px;
    }
  }
}

.fs12 {
  font-size: 12px !important;
}

.outlook_realtime_preview {
  .inner_row {
    background-color: var(--white);
    border: 1px solid var(--grey-d);
    border-radius: 10px;
    .left {
      .col_title {
        padding: 16px 30px;
      }
      .inbox_mailing_list {
        .single_item {
          display: flex;
          justify-content: space-between;
          padding: 12px 22px;
          position: relative;
          border-top: 1px solid var(--grey-d);
          .single_left {
            width: 100%;
            display: flex;
            .thumbnail {
              width: 35px;
              height: 35px;
              overflow: hidden;
              background: #d9d9d9;
              border-radius: 50%;
              display: flex;
              margin: 3px 0 0;
              align-items: center;
              min-width: 35px;
              justify-content: center;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
              svg {
                width: 14px;
                height: auto;
              }
            }
            .mail_content {
              padding: 0 0 0 15px;
              flex: 1;
              width: calc(100% - 35px);
              .name {
                max-width: calc(100% - 40px);
                font-weight: 600;
              }
              .subject_line {
                margin: 5px 0;
                color: #990000;
                max-width: calc(100% - 100px);
              }
              .preview_text {
                max-width: calc(100% - 30px);
              }
              .name,
              .subject_line,
              .preview_text {
                font-size: 14px !important;
                overflow: hidden;
                display: -webkit-box !important;
                text-overflow: ellipsis;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                width: 100%;
                word-break: break-all;
              }
            }
          }
          &.read_item {
            .name {
              font-weight: 400 !important;
            }
            .name,
            .subject_line {
              font-weight: 400;
              color: var(--grey-c) !important;
            }
          }
          .single_right {
            position: absolute;
            right: 22px;
            top: 12px;
            .delete_icon {
              text-align: right;
              img {
                width: 15px;
              }
            }
            span {
              font-size: 12px;
              color: #990000;
            }
          }
        }
      }
    }
    .right {
      border-left: 1px solid var(--grey-d);
      .inner_right {
        padding: 40px 50px;
        h3 {
          font-size: 21px !important;
        }
        .box_preview {
          .box_header {
            justify-content: space-between;
            display: flex;
            margin: 55px 0;
            align-items: flex-start;
            .box_header_left {
              display: flex;
              align-items: flex-start;
              flex: 1;
              .thumbnail_wrapper {
                width: 35px;
                height: 35px;
                overflow: hidden;
                background: #d9d9d9;
                border-radius: 50%;
                display: flex;
                margin: 3px 0 0;
                min-width: 35px;
                align-items: center;
                justify-content: center;
                svg {
                  width: 14px;
                  height: auto;
                }
              }
              .name_n_email {
                display: flex;
                flex-wrap: wrap;
                .hidden_time {
                  display: none;
                }
                h2 {
                  margin: 0 7px 0 20px;
                  line-height: 1;
                  font-size: unset !important;
                  h5 {
                    font-size: 14px !important;
                    overflow: hidden;
                    display: -webkit-box !important;
                    text-overflow: ellipsis;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    width: 100%;
                    word-break: break-all;
                    max-width: 152px;
                  }
                  span {
                    font-size: 12px !important;
                  }
                }
                h6 {
                  font-size: 12px !important;
                  text-underline-offset: 3px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 1;
                  width: 100%;
                  max-width: 270px;
                  word-break: break-all;
                  white-space: nowrap;
                  flex: 1;
                }
              }
            }
            .box_header_right {
              img {
                margin: 0 0 10px auto;
                display: block;
                &.dots_image {
                  display: none;
                }
              }
              p {
                font-size: 12px;
              }
            }
          }
          .box_body {
            padding: 0 0 0 55px;
            white-space: pre-line;
            color: var(--black-a);
          }
        }
      }
    }
  }
}
// closed

// responsive css

@media only screen and (min-width: 0) and (max-width: 767px) {
  .emailToolWrap {
    padding-bottom: 30px;

    .wrapper {
      padding: 0 !important;
      > div > div:not(.workingArea),
      .backToToolsBtn {
        padding: 0 28px !important;
      }
      .workingArea {
        border-radius: 0 !important;
        flex-direction: column;
        gap: 0;
        padding: 20px 20px 40px;

        & > div {
          width: 100%;
          max-width: 100%;
          flex: 1;
          .innerRight {
            textarea {
              height: 300px !important;
            }
          }
        }

        .leftArea {
          padding: 0 0 0px;
          display: flex;
          flex-flow: column;
          > h6 {
            order: -1;
          }
          .alertBox {
            margin-bottom: 20px;
            h6 {
              font-size: 12px !important;
            }
          }
        }
      }
    }
  }

  .emailLetterSec {
    .emailLetterContent {
      .iconWrap {
        display: none;
      }
      .secHeadingTitle {
        .secTitle {
          margin: 0 0 40px !important;
          font-size: 21px !important;
        }
      }
      .emailContent {
        position: relative;
        .emailwriteContent {
          width: 100%;
        }
        .emailThumb {
          position: absolute;
        }
        .emailwriteContent {
          .shareEmailContent {
            padding: 0 0 0 60px;
          }
          .letterText {
            font-size: 14px;
          }
        }
      }
      .rightSection {
        .iconWrap {
          display: block;
          padding: 5px 0 0;
          img {
            transform: rotate(90deg) translateY(50%);
          }
        }
      }
    }
  }

  .emailTableSection {
    table th {
      font-size: 12px;
      padding: 7px 15px;
      line-height: 1.33em;
    }
    tr td {
      padding: 12px 15px 4px !important;
      p,
      span,
      b {
        display: inline-block;
      }
    }
  }
  .nameTitle {
    max-width: calc(100% - 80px) !important;
  }

  .showMobile {
    display: inline-block !important;
    font-size: 12px !important;
  }
  .fs12 {
    font-size: 11px !important;
  }
  .hideMobile {
    display: none;
  }
}

@media only screen and (max-width: 580px) {
  .emailToolWrap {
    .upperGrid {
      .rightArea {
        margin: 20px 0 0 0;

        button {
          margin: 12px 0 0 !important;
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (min-width: 581px) and (max-width: 1250px) {
  .emailToolWrap {
    .upperGrid {
      .rightArea {
        margin: 20px 0 0 0;

        button {
          margin: 0;

          &.btnFilled {
            margin: 0 15px 0 0;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 820px) {
  .emailToolWrap {
    margin-top: -60px;
  }
}

@media (min-width: 821px) and (max-width: 899px) {
  .emailToolWrap {
    margin-top: -90px;
  }
}

@media (min-width: 900px) and (max-width: 1024px) {
  .emailToolWrap {
    margin-top: -110px;
  }
}

@media (max-width: 899px) {
  .outlook_realtime_preview {
    .wrapper {
      padding: 0 13px !important;
      .left {
        width: 100%;
        .col_title {
          padding: 14px 15px;
        }
        .inbox_mailing_list {
          width: 100%;
          .single_item {
            width: 100%;
            .single_left {
              .mail_content {
                .name {
                  max-width: calc(100% - 90px);
                }
                .subject_line,
                .preview_text {
                  font-size: 12px !important;
                  max-width: 100%;
                }
                .preview_text {
                  -webkit-line-clamp: 2;
                }
              }
            }
            .single_right {
              line-height: 1;
              .delete_icon {
                display: none;
              }
              span {
                line-height: 1;
              }
            }
            &.unread_item {
              position: relative;
              &::before {
                content: "";
                position: absolute;
                left: 7px;
                top: 31px;
                width: 8px;
                height: 8px;
                background: #a62020;
                border-radius: 50%;
                transform: translateY(-50%);
              }
            }
          }
        }
      }
      .right {
        border-left: none;
        border-top: 1px solid var(--grey-d);
        width: 100%;
        .inner_right {
          padding: 30px 15px;
          .box_preview {
            .box_header {
              margin: 35px 0;
              .box_header_left {
                width: calc(100% - 30px);
                h6:not(.hidden_time) {
                  display: none;
                }
                .hidden_time {
                  display: block !important;
                  min-width: 55px;
                  text-align: right;
                }
              }
              .box_header_right {
                p,
                img:not(.dots_image) {
                  display: none;
                }
                .dots_image {
                  display: block !important;
                  transform: rotate(90deg) translateY(10px) translateX(-5px);
                }
              }
            }
            .box_body {
              padding: 0;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .emailLetterSec .emailLetterContent {
    padding: 50px 40px 50px;
    .shareEmailContent {
      .hideMobile {
        display: none;
      }
      .showMobile {
        display: block !important;
      }
    }
    .rightSection {
      .iconWrap {
        display: block;
        padding: 5px 0 0;
        img {
          transform: rotate(90deg) translateY(50%);
        }
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .shareEmailContent {
    .leftSection {
      max-width: 59%;
      .titleName {
        min-width: fit-content;
      }
    }
  }
}

@media only screen and (min-width: 1025px) {
  .shareEmailContent {
    .leftSection {
      width: calc(100% - 300px);
      flex-wrap: wrap;
      .titleName {
        min-width: fit-content;
      }
    }
  }
}

@media (min-width: 900px) and (max-width: 1500px) {
  .outlook_realtime_preview {
    .inner_row {
      .right {
        width: 100%;
        .inner_right {
          padding: 30px 45px;
          .box_preview {
            .box_header {
              margin: 35px 0;
              .box_header_left {
                width: calc(100% - 30px);
                h6:not(.hidden_time) {
                  display: none;
                }
                .hidden_time {
                  display: block !important;
                  min-width: 55px;
                  text-align: right;
                }
              }
              .box_header_right {
                p,
                img:not(.dots_image) {
                  display: none;
                }
                .dots_image {
                  display: block !important;
                  transform: rotate(90deg) translateY(10px) translateX(-5px);
                }
              }
            }
            .box_body {
              padding: 0;
            }
          }
        }
      }
    }
  }
}
