.firstSection {
  padding: 0px 0 150px;
  @media (min-width: 900px) {
    margin-top: -240px;
  }

  // workingArea
  .workingArea {
    background-color: var(--white);
    border: 1px solid var(--grey-d);
    margin: 0 auto;
    border-radius: 10px;
    /*  padding: 30px 40px 0;
    gap: 0 60px; */
    width: 100%;
    .messageBoxes {
      label {
        color: var(--grey-c);
        font-size: 12px;
        font-weight: normal;
        line-height: 2;
        &:not(:first-child) {
          margin: 20px 0 0 0;
        }
      }
      textarea {
        border-radius: 5px;
        border: 1px solid var(--grey-a);
        background: var(--white);
        resize: none;
        padding: 18px 25px 25px 18px;
        outline: none !important;
        font-size: 16px;
        line-height: 1.5;
        &::placeholder {
          color: var(--grey-a);
          line-height: 1.5;
        }
      }
    }
    & > div {
      padding: 0;
    }
    .alertBoxWrapper {
      padding: 27px 43px;
      .alertBox {
        background-color: var(--light-peace);
        padding: 18px 15px;
        border-radius: 8px;
        svg {
          min-width: 20px;
        }
      }
    }
    .leftArea {
      border-top: 1px solid var(--grey-d);
      border-right: 1px solid var(--grey-d);
      padding: 20px 45px 50px !important;
      .textSMS {
        text-decoration: underline;
        text-underline-offset: 4px;
      }
    }
    .rightArea {
      background: url(../../components/images/phoneBackground.png) top
        center/574px auto no-repeat #f6f6f6;
      padding: 186px 68px 0 96px !important;
      min-height: 900px;
      position: relative;
      border-top: 1px solid #f6f6f6;
      display: flex;
      justify-content: center;
      flex: 1;
      background-position-y: 60px;
      .innerRight {
        width: 100%;
        max-width: 430px;
      }
      .greyText {
        color: var(--grey-b);
        text-align: center;
        margin: 0 0 25px 0;
      }
      .cutOffText {
        position: absolute;
        left: 0;
        right: 0;
        top: 840px;
        padding: 14px 68px 0 96px;
        border-top: 1px dashed var(--grey-a);
        z-index: 1;
      }
      h6:not(.greyText) {
        max-width: 335px;
        text-wrap: wrap;
        border-radius: 20px;
        background: var(--black-b);
        color: var(--white);
        padding: 20px 15px 20px 25px;
        margin: 0 0 30px;
        text-wrap: wrap;
        word-break: break-word;
        span {
          color: var(--white);
          display: block;
          /* margin: 20px 0 0; */
        }
      }
      h6:not(.cutOffText) {
        position: relative;
        z-index: 2;
      }
      .senderText {
        background-color: #f1f1f1;
      }
      &:after {
        content: "";
        background: url(../../components/images/phoneBackgroundBottom.png)
          center / 574px auto repeat-y;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        height: calc(100% - 170px);
        right: 0;
      }
    }
  }
}

// responsive css

@media only screen and (min-width: 0) and (max-width: 767px) {
  .firstSection {
    padding-bottom: 0;
    .wrapper {
      padding: 0 !important;
      .workingArea {
        flex-direction: column;
        gap: 0;
        border-radius: 0 !important;
        & > div {
          width: 100%;
          max-width: 100%;
          flex: 1;
        }
        .alertBoxWrapper {
          padding: 24px 28px;
          .alertBox {
            padding: 12px 13px;
            h6{
              font-size: 12px !important;
            }
          }
        }
        .leftArea {
          padding: 12px 28px 0 !important;
          border-right: 0 !important;
        }
        .rightArea {
          background-image: url("../../components/images/iphone1_Grey.png");
          min-height: 700px !important;
          padding: 110px 41px 0 41px !important;
          background-size: contain;
          margin: 0 auto;
          background-color: transparent;
          max-width: 390px;
          background-position-y: 20px;
          border: none;
          .innerRight {
            max-width: 280px;
            margin: 0 auto;
          }
          &:after {
            background-size: contain;
            max-width: 390px;
            margin: 0 auto;
            background-image: url("../../components/images/bottom-phone\ 5.png");
          }
          h6:not(.greyText) {
            max-width: 220px !important;
          }
          .cutOffText {
            top: 600px;
            width: calc(100% - 16px) !important;
            margin: 0 auto !important;
            padding: 14px 77px 0 75px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 580px) {
  .firstSection {
    .upperGrid {
      .rightArea {
        margin: 20px 0 0 0;
        button {
          margin: 12px 0 0 !important;
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .firstSection {
    .wrapper {
      padding: 0 !important;
      & > div > *:not(.workingArea) {
        padding: 0 28px !important;
      }
      .workingArea {
        border-radius: 0 !important;
        padding: 0 !important;
        .leftArea {
          .alertBox {
            padding: 12px !important;
            margin: 16px 0 !important;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 581px) and (max-width: 1250px) {
  .firstSection {
    .upperGrid {
      .rightArea {
        margin: 20px 0 0 0;
        button {
          margin: 0;
          &.btnFilled {
            margin: 0 15px 0 0;
          }
        }
      }
    }
  }
}


@media (min-width:768px) and (max-width:820px){
  .firstSection {
    margin-top:-60px;
  }
}



@media (min-width:821px) and (max-width:899px){
  .firstSection {
    margin-top:-90px;
  }
}


@media (min-width:900px) and (max-width:1024px){
  .firstSection {
    margin-top:-110px;
  }
}



@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .firstSection {
    .wrapper {
      .workingArea {
        gap: 0;
        justify-content: space-between;
        .leftArea {
          padding: 0;
          max-width: 450px;
          flex: 1;
        }
        .rightArea {
          min-height: 650px !important;
          background-position-x: center;
          padding: 110px 28px 0 43px !important;
          background-size: 350px;
          background-position-y: 20px;
          .innerRight {
            max-width: 260px;
            margin: 0 auto;
          }
          &:after {
            background-position-x: center;
            background-size: contain;
            max-width: 350px;
            margin: 0 auto;
          }
          h6:not(.greyText) {
            max-width: 200px !important;
            font-size: 14px !important;
          }
          .greyText {
            font-size: 12px !important;
          }
          .cutOffText {
            top: 600px;
            width: calc(100% - 16px) !important;
            margin: 0 auto !important;
            padding: 14px 77px 0 75px;
          }
        }
      }
    }
  }
}
