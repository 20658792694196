// First Section
.heroSectionInner {
  background-color: var(--primary-color);
  .rightArea {
    button {
      border-radius: 5px;
      margin: 0 5px;
      box-shadow: none;
      font-size: 14px;
      line-height: normal;
      font-weight: 700;
      padding: 14px 35px;
      transition: all 0.4s ease-in-out;
      text-transform: unset;
      &:hover {
        background-color: var(--secondary-color) !important;
        color: var(--white) !important;
        svg path {
          fill: var(--white);
        }
      }
      &.btnFilled {
        padding: 14px 50px !important;
        &:hover {
          background-color: var(--primary-color) !important;
        }
      }

      svg {
        margin: 0 10px 0 0;
        path {
          transition: all 0.4s ease-in-out;
        }
      }
    }
  }
}

@media (max-width: 820px) {
  .heroSectionInner {
    padding: 40px 0 20px;
  }
}

@media (min-width: 821px) and (max-width: 1024px) {
  .heroSectionInner {
    padding: 40px 0 80px;
  }
} 
@media (min-width: 900px) and (max-width: 1050px) {
  .heroSectionInner {
    .leftArea {
      h1 {
        font-size: 43px !important;
      }
    }
  }
}

@media (min-width: 1051px) and (max-width: 1200px) {
  .heroSectionInner {
    .leftArea {
      h1 {
        font-size: 52px !important;
      }
    }
  }
}
