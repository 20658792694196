.firstSection {
  padding: 0px 0 150px;
  @media (min-width: 900px) {
    margin-top: -240px;
  }

  // workingArea
  .workingArea {
    background-color: var(--white);
    border: 1px solid var(--grey-d);
    margin: 0 auto;
    border-radius: 10px;
    /*  padding: 30px 40px 0;
      gap: 0 60px; */
    width: 100%;
    .messageBoxes {
      label {
        color: var(--grey-c);
        font-size: 12px;
        font-weight: normal;
        line-height: 2;
        &:not(:first-child) {
          margin: 20px 0 0 0;
        }
      }
      textarea {
        border-radius: 5px;
        border: 1px solid var(--grey-a);
        background: var(--white);
        resize: none;
        padding: 18px 25px 25px 18px;
        outline: none !important;
        font-size: 16px;
        line-height: 1.5;
        &::placeholder {
          color: var(--grey-a);
          line-height: 1.5;
        }
      }
    }
    & > div {
      padding: 0;
    }
    .alertBoxWrapper {
      padding: 27px 43px;
      .alertBox {
        background-color: var(--light-peace);
        padding: 18px 15px;
        border-radius: 8px;
        svg {
          min-width: 20px;
        }
      }
    }
    .leftArea {
      border-top: 1px solid var(--grey-d);
      border-right: 1px solid var(--grey-d);
      padding: 0 0 0 !important;
      .tab_list {
        border-bottom: 1px solid var(--grey-d);
        align-items: flex-end;
        padding: 0 43px;
        > div > span {
          height: 3px;
          background: var(--secondary-color);
        }
        .single_tab_item {
          padding: 6px 0;
          min-height: unset;
          font-size: 16px;
          color: var(--black-a);
          min-width: -moz-fit-content;
          min-width: fit-content;
          font-weight: 400;
          text-transform: capitalize;
          &:not(:last-child) {
            margin: 0 35px 0 0;
          }
        }
      }
      .tab__content {
        padding: 0 43px 50px;
      }
      .adsBox {
        border-bottom: 1px solid #ddd;
      }
      .messageBoxes {
        label {
          color: var(--grey-c);
          font-size: 12px;
          font-weight: normal;
          line-height: 2;
          display: block;

          &:not(:first-child) {
            margin: 20px 0 0 0;
          }
        }

        textarea {
          border-radius: 5px;
          border: 1px solid var(--grey-a);
          background: var(--white);
          resize: none;
          padding: 18px 25px 25px 18px;
          outline: none !important;
          font-size: 16px;
          line-height: 1.5;

          &::placeholder {
            color: var(--grey-a);
            line-height: 1.5;
          }
        }
      }
      .newTextSMS_wrapper{
        margin-top: 16px;
        .newTextSMS{
          text-decoration: underline;
          margin: 0;
          width: fit-content;
          padding: 0 30px 0 0;
          background: #fff;
          position: relative;
        }
      }
      .newInputFields{
        display: flex;
        flex-direction: column; 
      }
      .textSMS_wrapper {
        position: relative;
        margin-top: 16px;
        .textSMS {
          margin: 0;
          width: fit-content;
          padding: 0 30px 0 0;
          background: #fff;
          position: relative;
        }
        &::before {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: 50%;
          transform: translate(0, -50%);
          height: 1px;
          background: var(--grey-d);
          z-index: 0;
        }
      }
      .inputFieldsContainer {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
      }

        .inputFields {
          flex: 1 1 45%;
          display: flex;
          flex-direction: column;
          &:not(:last-child){
            margin-bottom: 10px;
          }
      }

      .label {
        margin-bottom: 5px;
      }
      .numberFieldText {
        font-size: 12px;
        line-height: 2;
        margin-bottom: -20px;
        text-align: right;
        color: var(--grey-c);
      }
      input,
      textarea {
        padding: 13px 15px !important;
        height: unset !important;
      }
      span {
        font-size: 12px;
        color: #990000;
        &:not(.numberFieldText){
          width: calc(100% - 30px);
        }
      }
    }
    .rightArea {
      border-top: 1px solid var(--grey-d);
      border-right: 1px solid var(--grey-d);
      padding: 55px 50px 0 !important;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: #f6f6f6;
      .innerRight {
        // width: 100%;
        // max-width: 430px;
        background: url(../../components/images/phoneBackground.png) top
          center/574px auto no-repeat;
        padding: 220px 45px 50px !important;
        position: relative;
        min-height: 900px;
        max-width: 648px;
        justify-content: center;
        display: flex;
        flex: 1;
        height: 100%;
        background-position-y: 60px;
        margin: 0 auto;
        &::before {
          content: "";
          background: url(../../components/images/phoneBackgroundBottom.png)
            center / 574px auto repeat-y;
          width: 100%;
          position: absolute;
          left: 0;
          bottom: 0;
          height: calc(100% - 170px);
          right: 0;
          z-index: 0;
        }
      }
      .spandesktop {
        font-size: 20px;
        font-weight: 500;
      }
      .blue {
        color: rgb(75, 75, 226);
      }
      .viewmode {
        display: flex;
        align-items: center;
        justify-content: center;
        .view__btns {
          font-size: 12px !important;
          line-height: 1.33em;
          margin: 0 10px;
        }
      }
      .mainmobileview {
        padding: 0 12px 0 34px;
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        .mobileview {
          max-width: 440px;
          width: 100%;
          &:not(:last-child) {
            margin-bottom: 10px;
          }
          .display_preview {
            background-color: #eaeaea;
            padding: 20px 20px;
            &:not(:last-child){
              margin-bottom: 10px;
            }
          }
        }
      }
      .maindesktopview {
        padding: 70px 0 0 !important;
        .display_preview:not(:last-child) {
          margin: 0 0 55px;
        }
      }
      .demo_previews {
        height: 100%;
      }
      .notice_no_preview {
        display: none;
        @media (max-width: 767px) {
          display: block;
          padding: 80px 44px;
          text-align: center;
          .title {
            color: var(--primary-color);
            margin: 0 0 26px;
          }
          .toggle_text {
            color: var(--secondary-color);
            margin: 26px 0 0;
          }
        }
      }
    }
  }
}
.display_preview {
  .sponsored {
    font-size: 12px !important;
    line-height: 1.33333333em;
  }
  .web_info_full {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 0;
    .web_info_left {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: var(--grey-a);
      color: transparent;
    }
    .web_info_right {
      width: calc(100% - 45px);
      /* margin: 15px 0; */
      .business_name {
        font-size: 14px;
        color: #000;
        line-height: 1.3em !important;
      }
      .site_name {
        font-size: 12px !important;
        line-height: 1.5em;
      }
    }
  }
  .total_headings {
    .heading_2{
      &::before{
        content: "|";
        margin: 0 6px;
      }
    }
    span {
      font-size: 21px !important;
      color: #170fa4 !important;
      line-height: 1.2em;
    }
  }
  .description {
    font-size: 14px;
    margin: 2px 0 5px;
    line-height: 1.5em !important;
    word-break: break-all;
  }
  .site_links {
    font-size: 14px;
    line-height: 1.5em !important;
    color: #170fa4 !important;
    display: flex;
    flex-wrap: wrap;
    span{
      font-size: 14px;
      line-height: 1.5em !important;
      color: #170fa4 !important;
      position: relative;
      &:not(:last-child){
        margin: 0 20px 0 0;
      }
      &::before{
        content: "";
        width: 3px;
        height: 3px;
        position: absolute;
        background: #170fa4;
        border-radius: 50%;
        right: -14px;
        top: 50%;
        transform: translate(-50%, 0%);
      }
      &:last-child{
        &::before{
          display: none;
        }
      }
    }
  }
}
//reponsive css

@media only screen and (min-width: 0) and (max-width: 992px) {
  .firstSection {
    padding-bottom: 0;
    .wrapper {
      padding: 0 !important;
      .workingArea {
        flex-direction: column;
        gap: 0;
        border-radius: 0 !important;
        & > div {
          width: 100%;
          max-width: 100%;
          flex: 1;
          padding-inline: 0 !important;
        }
        .alertBoxWrapper {
          padding: 14px 20px;
          .alertBox {
            padding: 12px 13px;
            h6 {
              font-size: 12px !important;
            }
          }
        }
        .leftArea {
          padding: 0 !important;
          border: 0 !important;
          .tab_list {
            padding: 0 20px;
          }
          .tab__content {
            padding: 0 20px;
          }
          .adsBox {
            border-bottom: 1px solid #ddd;
          }
          .messageBoxes {
            margin-bottom: 39px;
            label {
              color: var(--grey-c);
              font-size: 12px;
              font-weight: normal;
              line-height: 2;
              display: block;

              &:not(:first-child) {
                margin: 20px 0 0 0;
              }
            }

            textarea {
              border-radius: 5px;
              border: 1px solid var(--grey-a);
              background: var(--white);
              resize: none;
              padding: 18px 25px 25px 18px;
              outline: none !important;
              font-size: 16px;
              line-height: 1.5;

              // &::placeholder {
              //   color: var(--grey-a);
              //   line-height: 1.5;
            }
          }
        }
        .textSMS {
          text-underline-offset: 4px;
        }
        .inputFieldsContainer {
          display: inline;
        }

        .inputFields {
          flex: 1 1 45%;
          display: flex;
          flex-direction: column;
        }

        .label {
          margin-bottom: 5px;
        }
      }
    }

    .rightArea {
      border-top: 1px solid var(--grey-d);
      border-right: 1px solid var(--grey-d);
      background-color: #f6f6f6;
      .demo_previews {
        .innerRight {
          background-image: url("../../components/images/iphone1_Grey.png") !important;
          min-height: 100px !important;
          padding: 130px 33px 80px 36px !important;
          background-size: 100%;
          margin: 0 auto;
          background-color: transparent;
          background-position-y: 20px !important;
          border: none;
          max-width: 450px;
          &:before {
            background-image: url("../../components/images/bottom-phone\ 5.png") !important;
            background-position-x: center;
            background-size: 100%;
            max-width: 450px;
            margin: 0 auto;
          }
        }
      }

      .spandesktop {
        margin-left: -235px;
      }
      .blue {
        color: rgb(75, 75, 226);
      }
      .mainmobileview {
        padding: 0 !important;
        .mobileview {
          max-width: 360px !important;
        }
        .display_preview {
          padding: 13px !important;
          .total_headings span {
            font-size: 18px !important;
          }
          .description {
            font-size: 13px !important;
          }
          .site_links {
            font-size: 13px !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 580px) {
  .firstSection {
    .upperGrid {
      .rightArea {
        margin: 20px 0 0 0;
        button {
          margin: 12px 0 0 !important;
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .firstSection {
    .wrapper {
      padding: 0 !important;
      & > div > *:not(.workingArea) {
        padding: 0 28px !important;
      }
      .workingArea {
        border-radius: 0 !important;
        padding: 0 !important;
        .leftArea {
          .alertBox {
            padding: 12px !important;
            margin: 16px 0 !important;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 581px) and (max-width: 1250px) {
  .firstSection {
    .upperGrid {
      .rightArea {
        margin: 20px 0 0 0;
        button {
          margin: 0;
          &.btnFilled {
            margin: 0 15px 0 0;
          }
        }
      }
    }
  }
}

/* @media (min-width: 768px) and (max-width: 820px) {
  .firstSection {
    margin-top: -60px;
  }
} */

@media (max-width: 767px) {
  .outer_card {
    display: none;
  }
  .maindesktopview {
    display: none;
  }
}

@media (min-width: 821px) and (max-width: 899px) {
  .firstSection {
    margin-top: -90px;
  }
}

@media (min-width: 900px) and (max-width: 1024px) {
  .firstSection {
    margin-top: -110px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .firstSection {
    /* margin-top: -98px; */
    .wrapper {
      .workingArea {
        gap: 0;
        justify-content: space-between;
        /* margin-top: -110px; */
        .leftArea {
          border-top: 1px solid var(--grey-d);
          border-right: 1px solid var(--grey-d);
          .adsBox {
            border-bottom: 1px solid #ddd;
          }
          .messageBoxes {
            label {
              color: var(--grey-c);
              font-size: 12px;
              font-weight: normal;
              line-height: 2;
              display: block;

              &:not(:first-child) {
                margin: 20px 0 0 0;
              }
            }

            textarea {
              border-radius: 5px;
              border: 1px solid var(--grey-a);
              background: var(--white);
              resize: none;
              padding: 18px 25px 25px 18px;
              outline: none !important;
              font-size: 16px;
              line-height: 1.5;

              &::placeholder {
                color: var(--grey-a);
                line-height: 1.5;
              }
            }
          }
          .textSMS {
            text-underline-offset: 4px;
          }
          .inputFieldsContainer {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
          }

          .inputFields {
            flex: 1 1 45%;
            display: flex;
            flex-direction: column;
          }

          .label {
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .firstSection {
    .workingArea {
      .rightArea {
        .maindesktopview {
          padding: 70px 60px 60px !important;
        }
      }
    }
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  .firstSection {
    padding-bottom: 90px;
    .rightArea {
      border-top: 1px solid var(--grey-d);
      border-right: 1px solid var(--grey-d);
      background-color: #f6f6f6;
      .demo_previews {
        .innerRight {
          background-image: url("../../components/images/iphone1_Grey.png") !important;
          min-height: 100px !important;
          padding: 130px 33px 0 36px !important;
          background-size: 100%;
          margin: 0 auto;
          background-color: transparent;
          background-position-y: 20px !important;
          border: none;
          max-width: 450px;
          &:before {
            background-image: url("../../components/images/bottom-phone\ 5.png") !important;
            background-position-x: center;
            background-size: 100%;
            max-width: 450px;
            margin: 0 auto;
          }
        }
      }
      .blue {
        color: rgb(75, 75, 226);
      }
      .mainmobileview {
        padding: 0 !important;
        .mobileview {
          max-width: 360px !important;
        }
        .display_preview {
          padding: 13px !important;
          .total_headings span {
            font-size: 18px !important;
          }
          .description {
            font-size: 13px !important;
          }
          .site_links {
            font-size: 13px !important;
          }
        }
      }
    }
  }
}

// social-ad_preview scss
.outer_card {
  padding: 50px 57px 55px 57px !important;

  .ad_card {
    max-width: 100%;
    border-radius: 15px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

    .ad_card_header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 0;

      .business_info {
        display: flex;
        align-items: center;

        .business_avatar {
          position: relative;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: #DDDDDD;
        }
      }

      .post_details {
        color: #888888;
        font-size: 12px;
      }
    }

    .ad_card_media {
      height: 580px !important;
      background-color: #DDDDDD;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .middle_ad_card_media{
      height: 450px;
      background-color: #DDDDDD;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .picture_media {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .right_picture_media {
        align-items: center;
        display: flex;
        padding-right: 20px;
        .button_action {
          background-color: #EEEEEE;
          color: #333333;
          font-weight: 700;
          box-shadow: none;
          height: 40;

          &:hover {
            background-color: #eeeeee81;
          }
        }
      }
    }
    .ad_headlines {
      font-weight: bold;
    }

    .ad_description {
      color: #555555;
    }
  }
}

.preview_mobile_view {
  padding: 0 10px 0 34px;
  position: relative;
  width: 100%;
  max-width: 505px;
  @media (max-width: 1228px) and (min-width: 1200px) {
    padding: 0 5px 0 28px
  }
  @media (max-width:1200px) {
    padding: 0;
  }
  .mobileOuterClass{
    padding: 0px 15px 0px 15px;
    background-color: #ffff;
    .post_details {
      color: #888888;
      font-size: 14px;
    }
  }
  .business_info {
    display: flex;
    align-items: center;
    padding: 12px 0px 12px 0px;
    .business_avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #DDDDDD;
    }
    
  }
    .postCopyMobile {
      padding-bottom: 10px;
    }
    .middle_ad_card_media{
      height: 450px !important;
      background-color: #DDDDDD;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .ad_card_media {
      height: 580px !important;
      background-color: #DDDDDD;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .picture_media {
      margin: 0%;
      background-color: #ffff;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .left_picture_media {
        padding: 15px 18px 15px 15px;
      }
      .right_picture_media {
        align-items: center;
        display: flex;
        padding-right: 20px;
  
        .button_action {
          background-color: #EEEEEE;
          color: #333333;
          font-weight: 700;
          box-shadow: none;
          height: 50px;
          width: 140px;
  
          &:hover {
            background-color: #eeeeee81;
          }
        }
      }
    }
    .ad_headlines {
      font-weight: bold;
    }

    .ad_description {
      color: #555555;
    }
    }
