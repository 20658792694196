// Top Bar
.topBar {
  padding: 23px 0;
  .text,
  .text a {
    color: var(--white) !important;
    text-decoration: none;
  }
  .logoMainWrap {
    margin: 8px 0 0;
  }
  .leftArea {
    .new_Upper_URL {
      display: flex;
      align-items: center;
      justify-content: space-between;
      svg {
        margin: 0 0 0 14px;
        width: 40px;
      }
    }
  }
}
// Nav Bar
.navBar {
  padding: 40px 0 40px;
}

@media only screen and (max-width: 820px) {
  .topBar {
    padding: 16px 0 8px;
    .container {
      .logoMainWrap {
        margin: 40px 0 0;
        svg {
          width: 100px;
          height: auto;
        }
      }
      padding: 0 24px !important;
      .gridContianer {
        flex-flow: column-reverse;
        .rightArea {
          min-width: fit-content;
        }
        .right_Column {
          width: 100%;
          .leftArea {
            width: 100%;
            p {
              font-size: 14px;
              line-height: 1.28 !important;
            }
            svg{
              margin: 0;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 821px) and (max-width: 899px) {
  .topBar {
    .container {
      .logoMainWrap {
        svg {
          width: 100px;
          height: auto;
        }
      }
      .leftArea {
        p {
          font-size: 15px;
        }
      }
    }
  }
}
