@font-face {
font-family: 'F37 Gruffy'; src: url('./components/fonts/F37Gruffy-Light.woff2') format('woff2'),url('./components/fonts/F37Gruffy-Light.woff') format('woff'); font-weight: 300; font-style: normal; font-display: swap; }
@font-face {
font-family: 'F37 Gruffy'; src: url('./components/fonts/F37Gruffy-ExtraBold.woff2') format('woff2'),url('./components/fonts/F37Gruffy-ExtraBold.woff') format('woff'); font-weight: bold; font-style: normal; font-display: swap; }
@font-face {
font-family: 'F37 Gruffy'; src: url('./components/fonts/F37Gruffy-Medium.woff2') format('woff2'),url('./components/fonts/F37Gruffy-Medium.woff') format('woff'); font-weight: 500; font-style: normal; font-display: swap; }
body { margin: 0; font-family: 'Inter',sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; box-sizing: border-box; background-color: var(--light-peace); }
* { box-sizing: border-box; }
a { outline: none!important; }
code { font-family: 'Inter',sans-serif; }

/* Global style - start */
:root { --primary-color: #342354; --secondary-color: #F07561; --accent-color: #F9F6ED; --grey-a: #CCCCCC; --grey-b: #999999; --grey-c: #666666; --grey-d: #DDDDDD; --black-a: #333333; --black-b: #222222; --green-a: #23C155; --light-peace: #FCF4ED; --white: #ffffff; --accent-purple-a: #A88CC1; --accent-purple-b: #7C6195; }
.MuiGrid-container:not(.csMargin) { margin-top: 0!important; }
.MuiGrid-item:not(.csPadding) { padding-top: 0!important; }
h1, h2, h3, h4, h5, h6, span { color: var(--black-a); }
h1 { font-size: 60px!important; }
h2 { font-size: 42px!important; }
h3 { font-size: 36px!important; }
h4 { font-size: 30px!important; }
h5 { font-size: 24px!important; }
h6 { font-size: 18px!important; }
.MuiTypography-subtitle1 { font-size: 14px!important; }
.MuiTypography-subtitle2 { font-size: 14px!important; }
p { line-height: 1.75!important; }
a, button, h1, h2, h3, h4, h5, h6, input, p, select, span, table, td, textarea, th { font-family: 'Inter',sans-serif!important; /* word-break: break-all; */ }
.noWordBreak { word-break: normal; }
.MuiContainer-root { max-width: 1456px!important; padding: 0 28px!important; }
.gray-b-text { color: var(--grey-b); }
.bgDarkPurple { background-color: var(--primary-color)!important; }
.bgLightGreen { background-color: var(--green-a)!important; }
.bgCoral { background-color: var(--secondary-color)!important; }
.fontJBM { font-family: 'JetBrains Mono',monospace!important; }
.fontP22 { font-family: p22-mackinac-pro,serif!important; }
.fontGruffy { font-family: 'F37 Gruffy'!important; }
.textJBM { color: var(--secondary-color)!important; font-size: 16px!important; font-weight: bold!important; letter-spacing: 1.6px!important; text-decoration: none!important; display: inline-block; font-family: 'JetBrains Mono',monospace!important; }
.textCoral { border-color: var(--secondary-color)!important; color: var(--secondary-color)!important; outline-color: var(--secondary-color); }
.mt-20 { margin-top: 20px; }
span.underlined { text-decoration: underline; text-underline-offset: 2px; }
.blockContent { display: block; }
.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiInputBase-formControl:hover .MuiOutlinedInput-notchedOutline, textarea:focus-visible, textarea:hover { border: 1px solid var(--secondary-color)!important; box-shadow: none!important; outline: none!important; }
.inner_Banner_Title { padding-right: 20px; }
/* Global style - end */

/* email preview page */
.emailTableSection .MuiTableContainer-root { box-shadow: none; }
/* preview page closed */

/* NEW HOME PAGE CSS STARTS */
.home_tab_section .MuiTabs-indicator { background-color: var(--secondary-color)!important; }
.home_tab_section .Mui-selected { -webkit-text-stroke-width: 0.7px; }
/* NEW HOME PAGE CSS ENDS */

.home_tab_section .Mui-selected, .search_ads_tabs .Mui-selected { -webkit-text-stroke-width: 0.7px; }
/* NEW HOME PAGE CSS ENDS */

/* search ads preview page css starts */
.switch_method { height: 25px!important; padding: 0!important; border-radius: 100px; align-items: center; width: 50px!important; }
.switch_method .MuiSwitch-switchBase { padding: 0; width: 100%; left: 0!important; transform: unset!important; height: 100%; background: var(--primary-color)!important; transition: all 0.4s ease; border-radius: 0!important; }
.switch_method .MuiSwitch-switchBase .MuiSwitch-input { width: 100%!important; left: 0!important; }
.switch_method .MuiSwitch-switchBase span.MuiSwitch-thumb { position: absolute; left: 2px; transition: all 0.4s ease; width: 21px; height: 21px; background: #fff; box-shadow: unset; }
.switch_method .MuiSwitch-switchBase.Mui-checked span.MuiSwitch-thumb { left: calc(100% - 23px); }
.switch_method .MuiSwitch-switchBase.Mui-checked { background: var(--secondary-color)!important; }
/* search ads preview page css ends */

@media only screen and (max-width:767px) {
  h6 { font-size: 16px!important; }
  .inner_Banner_Title { padding-right: 0; }
}

@media (min-width:1025px) {
  .heroSectionInner { min-height: 525px; }
}

@media (max-width:820px) {
  h1 { font-size: 42px!important; }
}

@media (min-width:1201px) and (max-width:1250px) {
  .inner_Banner_Title { padding-right: 0; }
}
